import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../components/Page";
import Container from "../components/Container";

import { PDFwhite } from "../images/svg";
const IndexPage = () => {
  return (
    <Page
      metaTitle="Saint Gobain uPVC Windows and Doors in Aizawl, Mizoram"
      metaDescription="The global leader in building materials, introduces revolutionary uPVC window technologies, never seen before in Aizawl, Mizoram, India."
      className="home-page"
    >
      <section className="hero">
        <Container>
          <StaticImage
            src="../images/saint-gobain-upvc-windows-aizawl-mizoram.png"
            alt="Saint Gobain uPVC Windows and Doors, Aizawl Mizoram"
            loading="eager"
            placeholder="none"
            layout="fullWidth"
          />
          <h1>
            <strong>uPVC Windows</strong>
            <span>in Aizawl, Mizoram</span>
          </h1>
          <div className="tagline">
            <strong>Elegant</strong>
            <i></i>
            <strong>Effortless</strong>
            <i></i>
            <strong>Everlasting</strong>
          </div>
          <div className="link">
            <a href="/Saint-Gobain-uPVC-Windows-Brochure.pdf" target="_blank">
              <PDFwhite />
              <b>Download Brochure</b>
            </a>
          </div>
        </Container>
      </section>
      <section className="intro">
        <Container>
          <p>
            The global leader in building materials, introduces revolutionary{" "}
            <strong>uPVC window technologies</strong>, never seen before in{" "}
            <em>Aizawl, Mizoram</em> India.
          </p>
        </Container>
      </section>
      <section className="usps">
        <Container>
          <h2>
            <strong>Why Saint Gobain uPVC Windows</strong>
          </h2>
        </Container>
        <div className="usp">
          <Container>
            <figure>
              <StaticImage
                src="../images/whitest-upvc-windows-profile.jpg"
                alt="Whitest uPVC Window profile in Aizawl, Mizoram, India"
                loading="lazy"
                placeholder="blurred"
                layout="fullWidth"
              />
            </figure>
            <div className="txt">
              <h3>
                <strong>Whitest uPVC Profile</strong>
              </h3>

              <p>
                Colour test is the measurement of uPVC Profile colour values (L
                *, a*, b*) using an instrument called Spectrophotometer. With an
                L-value greater than 96.5, Saint-Gobain has the whitest profiles
                in India. This is achieved by using the best quality raw
                materials as well as the highest quantity of Titanium Dioxide
                (TiO2) used by any manufacturer in India.
              </p>
            </div>
          </Container>
        </div>
        <div className="usp">
          <Container>
            <figure>
              <StaticImage
                src="../images/seamless-upvc-zip-joint.jpg"
                alt="Seamless Joint, uPVC Windows in Aizawl, Mizoram"
                loading="lazy"
                placeholder="blurred"
                layout="fullWidth"
              />
            </figure>
            <div className="txt">
              <h3>
                <strong>Seamless uPVC Zip Welding</strong>
              </h3>
              <p>
                For the first time in India, Saint-Gobain is introducing a
                revolutionary patented welding technology called Seamless Zip
                Welding. Not only is it stronger than traditional welding, it
                also has the best finish of any manufacturer in India.
              </p>
            </div>
          </Container>
        </div>
        <div className="usp">
          <Container>
            <figure>
              <StaticImage
                src="../images/superior-winsteel-upvc-reinforcements.jpg"
                alt="uPVC Windows with superior reinforcement in Aizawl, Mizoram"
                loading="lazy"
                placeholder="blurred"
                layout="fullWidth"
              />
            </figure>
            <div className="txt">
              <h3>
                <strong>WinSteel uPVC Reinforcements</strong>
              </h3>
              <p>
                Saint Gobain patented WinSteel reinforcement that is 7 times
                superior against rusting than conventional steel reinforcement,
                making the window exceptionally durable.
              </p>
            </div>
          </Container>
        </div>
      </section>
      <section className="brochure-download">
        <Container>
          <div className="overlay">
            <h3>
              <strong>Saint Gobain uPVC Windows</strong>
            </h3>
            <a href="/Saint-Gobain-uPVC-Windows-Brochure.pdf" target="_blank">
              <PDFwhite />
              <b>Download Brochure</b>
            </a>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default IndexPage;
